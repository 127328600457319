import { request } from 'utils/api';

let gladly;

const waitForGladly = (timeout = 5000) =>
  new Promise((resolve, reject) => {
    const start = Date.now();
    const interval = setInterval(() => {
      if (window.Gladly) {
        clearInterval(interval);
        gladly = window.Gladly;
        resolve(gladly);
      } else if (Date.now() - start > timeout) {
        clearInterval(interval);
        reject(
          new Error('window.Gladly did not become available within timeout')
        );
      }
    }, 100);
  });

const getGladlyUser = () => {
  return gladly && gladly.getUser ? gladly.getUser() : null;
};

/**
 * Sets the Gladly user in the application.
 *
 * This function is called in the following scenarios:
 * 1. When the user logs in (see: ../web/src/public/screens/Auth/Login.js)
 * 2. When the page is refreshed (see: ../web/src/public/containers/DefaultLayout/DefaultLayout.js)
 */
export const setGladlyUser = async () => {
  try {
    await waitForGladly();

    const gladlyUser = getGladlyUser();
    if (gladlyUser) {
      return;
    }

    await identifyGladlyUserWithJWT();
  } catch (error) {
    console.error('Error setting Gladly user from store:', error);
    clearGladlyUser();
  }
};

export const identifyGladlyUserWithJWT = () => {
  return request({
    path: '/1/gladly/user',
  })
    .then((response) => {
      const { name, email } = response;

      if (gladly && gladly.setUser) {
        gladly.setUser({
          name,
          jwt: async () => {
            const tokenResponse = await request({
              method: 'POST',
              path: '/1/gladly/jwt',
              body: { email },
            });
            const token = tokenResponse.token;
            return token;
          },
        });
      }
    })
    .catch((error) => {
      console.error('Error fetching JWT:', error);
    });
};

/**
 * Clears the Gladly user in the application.
 *
 * This function is called in the following scenarios:
 * 1. When the user logs out (see: ../web/src/public/screens/Auth/Logout.js)
 */
export const clearGladlyUser = () => {
  if (gladly && gladly.clearUser) {
    gladly.clearUser();
  }
};
